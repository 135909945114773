import { IEnum_Solution_Type, ISolutionCaseStudyFragment } from 'src/graphql/generated/hooks'
import { IEnum_Componentsolutionpropsdedicatedresource_Type } from 'src/graphql/generated/requests'

export type FilterOptionItem = {
  id: string
  name: string
  slug?: string | null | undefined
  isPremium?: boolean | null | undefined
  children?: FilterOptionItem[] | null | undefined
  allChildrenSelected?: boolean | null | undefined
  selected?: boolean | null | undefined
  indeterminate?: boolean | null | undefined
  opened?: boolean | null | undefined
  level?: number | null | undefined
}

export interface CaseStudy extends Pick<ISolutionCaseStudyFragment, 'name' | 'link'> {}

export interface SponsorLevelFilterOptionItem extends FilterOptionItem {
  level: number | null | undefined
}

export type FilterOption = {
  title: TaxonomyType
  slug?: string
  data: FilterOptionItem[]
}

export type EventSubOption = {
  id: string
  name: string
}

type SectionItemType =
  | 'SpanDataList'
  | 'SpanDataIcon'
  | 'DottedDataList'
  | 'string'
  | 'number'
  | 'dedicated'
  | 'CustomList'
  | 'CheckDataList'
  | 'LinkedinIconLink'
  | 'TwitterIconLink'

export interface SectionItem {
  title?: string
  type: SectionItemType
  subType?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: FeaturesType[] | any[]
  sValue?: string
  nValue?: number
  isHorizontal?: boolean
  isFullWidth?: boolean
  dValue?: DedicatedResourcesType[]
  className?: string
  colValue?: number
  publishedAt?: string
}

export type DedicatedResourcesType = {
  count?: number
  type: IEnum_Componentsolutionpropsdedicatedresource_Type
}

export type VendorResources = {
  title: string
  link: string
}

export type Founders = {
  id?: string | null | undefined
  name: string
  title: string
  externalLink: string
  avatar: Avatar
}

export type Specialists = {
  name: string
  role: string
  bio: string
}

export type FeaturesType = {
  name: string
  isChecked?: boolean
  icon?: string
  iconColor?: string
  link?: string
}

export type SimpleSubTopicType = {
  name: string
  slug: string
}

export type ListingScreenshotType = {
  title: string
  summary: string
  image: string
  file?: File
}

export type ListingFounderType = {
  name: string
  avartar: string
  file?: File
  role: string
  bio: string
}

export type ListingTestimonialType = {
  description: string
  author: {
    name: string
    role: string
    company: string
    avatar: string
    file?: File
  }
}

export type TopicOption = {
  id: string
  label: string
}

export const CompanyTypesOptions = ['ALSP', 'Consultant', 'Law department', 'Law firm', 'Vendor', 'Other']

export const CompanySizeOptions = [
  'ALSP in early stage',
  'ALSP in mature stage',
  'Consultant with less than 25 consultants',
  'Consultant with more than 25 consultants',
  'Law firm with 5 to 25 lawyers',
  'Law firm with 25 to 100 lawyers',
  'Law firm with 100 to 250 lawyers',
  'Law firm with 250 to 1000 lawyers',
  'Law firm with less than 5 lawyers',
  'Law firm with more than 1000 lawyers',
  'Legal Tech Vendor in early stage',
  'Legal Tech Vendor in mature stage',
  'Legal department with 5 to 25 lawyers',
  'Legal department with 25 to 100 lawyers',
  'Legal department with less than 5 lawyers',
  'Legal department with more than 100 lawyers',
  '1-10 employees',
  '11-50 employees',
  '51-200 employees',
  '201-500 employees',
  '501-1000 employees',
  '1000+ employees',
]
export const PremiumPricingOptions = [
  'Lawfirms with more than 250 lawyers ($5,000)',
  'Lawfirms with 101-250 lawyers ($2,500)',
  'Lawfirms with 26-100 lawyers ($1,000)',
  'Lawfirms with 25 lawyers or less ($500)',
  'In house departments in Fortune 1,000/FTSE 100 companies, local equivalents or more than 100 lawyers ($2,500)',
  'In-house departments with 26-100 lawyers ($1,000)',
  'In-house departments with 25 lawyers or less ($500)',
  'Other ($5,000)',
]

export interface SessionType {
  user: {
    name: string
    email: string
    idToken: string
    id: string
    confirmed: boolean
    companyName: string
    isPremium: boolean
    isVendor: boolean
    isSSOUser: boolean
    vendor: string
    publishedAt: string
  }
  expires: string
}

export interface CompareSolution {
  id: string
  logo: string
  slug: string
  solutionType: IEnum_Solution_Type
}

export interface OverSection {
  isTotal: boolean
  isHqsData: boolean
  isOfficesData: boolean
  isLangsData: boolean
  isRegionsData: boolean
  isServicesData: boolean
}

export interface AreaSection {
  isTotal: boolean
  isAudienceData: boolean
  isTypeUserData: boolean
  isCustomerData: boolean
  isAreaData: boolean
  isEngagementLenData: boolean
  isResourcesData: boolean
  isBreakdownsData: boolean
  isPropositionsData: boolean
}

export interface FeatureSection {
  isTotal: boolean
  isSubTopicsData: boolean
  isAttributesData: boolean

  isFeaturesData: boolean
  isDeploymentData: boolean
  isIntegrationData: boolean
  isOperatingSinceData: boolean
  isSpecialData: boolean
  isSizeData: boolean
  isPartnersData: boolean
}

export type Avatar = {
  id?: string
  url?: string
}

export type TestimonialTypeWithId = {
  id?: string | null | undefined
  description: string
  author: {
    name: string
    role: string
    company: string
    avatar: Avatar
  }
}

export enum TaxonomyType {
  Audience = 'audience',
  PracticeArea = 'practice area',
  Language = 'language',
  Deployment = 'deployment',
  Attribute = 'attribute',
  EventFeature = 'event feature',
  RegionsServed = 'regions served',
  JurisdictionsServed = 'jurisdictions served',
  HQ = 'hq',
  Subtopic = 'subtopics',
  Topic = 'topic',
  ProductType = 'product type',
  Format = 'format',
  Location = 'location',
  Organization = 'organization',
  Duration = 'duration',
  ResourceType = 'resource type',
  ContentType = 'content type',
  SubjectMatter = 'subject matter',
  Phase = 'phase',
  Theme = 'theme',
  DocumentType = 'document type',
  Author = 'author',
  Date = 'date',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Subject = 'subject',
  SponsorLevel = 'sponsor level',
  vendorTopic = 'vendor topic',
}

export const contentTypes = [
  'Landscape Article',
  'Evaluation Framework',
  'Requirements Template',
  'Business Case',
  'Competitive Analysis',
  'Pilot Guide',
  'Thought Leadership',
  'KnowHow',
  'Legaltech Resources for Lawyers',
  'Blog',
  'Vendor Resource',
  'Industry Analysis',
]

export interface ItemsData {
  id: string
  name: string
  value: string | number
}

export const DETAILS_TAB = 0
export const REVIEWS_TAB = 1
export const VIDEOS_TAB = 2
export const PRICING_TAB = 3
export const INSIGHTS_TAB = 4
export const CASE_TAB = 5
export const RESOURCE_TAB = 6
export const VENDOR_ANALYSIS_TAB = 7
export const MOBILE_MAX_WIDTH = 768
export const SEARCH_DIRECTORY_TAB = 401
export const SEARCH_CONTENTS_TAB = 402
export const COMPANIES_DETAILS_TAB = 401
export const COMPANIES_DIRECTORY_TAB = 402

export const YOUR_RESOURCES_TAB = 500
export const SHARED_RESOURCES_TAB = 501
export const INVITED_USERS_TAB = 502
