import { Menu, Transition } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Link from 'next/link'
import React, {
  Fragment,
  //useState
} from 'react'
import { LabelLink } from 'src/components/global/LabelLink'
import * as enVariables from 'src/config/config'
import { LTH_SOLUTION_MENU } from 'src/constants/constants'

//import styles from './Header.module.scss'

/**
 * Primary UI component for user interaction
 */

const lthSolutionMenu = LTH_SOLUTION_MENU

export const SolutionsMenu: React.FC = () => {
  //const [topicCurrentIdx, setTopicCurrentIdx] = useState(0)

  return (
    <Menu as="div" className={cn('relative inline-block h-full w-[120px] ml-[5px] mr-[10px] cursor-pointer text-left')}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={cn(
              'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
              { 'bg-white bg-opacity-5': open },
            )}
          >
            LTH Solutions
            <IonIcon
              className="text-white mr-0 !important"
              name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </Menu.Button>
          {open && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-30 overflow-hidden flex flex-row w-[375px] h-[13rem] rounded-[1.25rem] shadow-lg bg-white absolute"
                style={{ top: '60px' }}
              >
                <div className="absolute py-4 w-[375px] h-full overflow-y-auto">
                  <ul>
                    {lthSolutionMenu &&
                      lthSolutionMenu.map((item: any, index: number) => {
                        return (
                          <li className="px-3 h-10 w-full" key={'solution' + index}>
                            <LabelLink
                              key={'solution-' + index}
                              href={item?.slg}
                              className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                              label={item?.title}
                            />
                          </li>
                        )
                      })}
                    <li className="px-3 h-12 w-full">
                      <Link
                        href={enVariables.JOB_URL}
                        passHref
                        target="_blank"
                        rel="noopener noreferrer"
                        className={cn(
                          /* styles.menuItem, */
                          'font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10',
                        )}
                      >
                        Legal<span className="text-turquoise">tech </span>&nbsp;Jobs
                      </Link>
                    </li>
                  </ul>
                </div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  )
}
